import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsletterSignUp from "../components/newsletterSignUp"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />

      <div className="flex">
        <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-10">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div className="mb-8 sm:mb-0" key={node.fields.slug}>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  <article
                    key={node.fields.slug}
                    className="transition duration-200 ease-in-out flex flex-col justify-between border-black border-2 p-4 border-t-8 sm:h-64 hover:text-white hover:bg-black"
                  >
                    <header>
                      <h3 className="mb-4 text-5xl font-bold">{title}</h3>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </section>
                    <div className="text-right">
                      <small>{node.frontmatter.date}</small>
                    </div>
                  </article>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
      <NewsletterSignUp />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 120)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image
          }
        }
      }
    }
  }
`
